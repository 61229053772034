<template>
  <b-overlay :show="isBusy" rounded="lg" opacity="0.6" no-body>
    <b-modal
      id="modal-center"
      v-model="showModal"
      scrollable
      header-class="bg-white"
      hide-footer
      size="xl"
    >
      <!-- <p class="my-4">{{ modalValue.item }}</p> -->
      <div v-if="showModal">
        <total-case-view :casedata="caseData"> </total-case-view>
      </div>
    </b-modal>

    <b-modal
      id="modal-center"
      v-model="showTranscript"
      scrollable
      header-class="bg-white"
      hide-footer
      size="lg"
    >
      <!-- <p class="my-4">{{ modalValue.item }}</p> -->

      <div>
        <b-list-group>
          <b-list-group-item
            v-for="(call, index) in transcript"
            :key="index"
            :variant="`${call.speaker === 'caller' ? 'info' : ''}`"
          >
            <div class="flex flex-row">
              <div style="min-width: 120px">
                {{ call.time }} |
                <span class="font-bold">{{ call.speaker }}:</span>
              </div>

              <div class="border-left pl-2">{{ call.phrase }}</div>
            </div>
          </b-list-group-item>
        </b-list-group>
      </div>
    </b-modal>
    <b-card v-if="true" no-body class="card-company-table">
      <div>
        <b-table
          ref="table"
          class="w-full mb-0 min-h-6xl text-xs"
          show-empty
          border
          select-mode="multi"
          :fields="fields"
          responsive
          :items="contactData"
        >
          <template #cell(created_date)="data">
            <b-col class="border rounded-lg px-2 text-center">
              <b-row>{{ data.item.created_date[0] }} </b-row>
              <b-row>{{ data.item.created_date[1] }} </b-row>
            </b-col>
          </template>
          <template #cell(phone_number)="data">
            <div style="min-width: 90px">
              {{ fPhone(data.item) }}
            </div>
          </template>
          <template #cell(caller)="data">
            <div class="text-sm font-bold">
              {{ data.item.caller }}
            </div>
          </template>
          <template #cell(case_number)="data">
            <b-button
              v-if="data.item.case_number != 'case ID not provided'"
              variant="gradient-secondary"
              class="shadow-md btn-icon"
              size="sm"
              @click="getCaseData(data.item.case_number)"
            >
              <div class="flex flex-row items-center justify-center">
                <div class="flex flex-row text-xs">View</div>
              </div>
            </b-button>
            <div v-else>{{ data.item.case_number }}</div>
          </template>

          <template #cell(transcription)="data">
            <b-button
              v-if="data.item.transcription"
              variant="gradient-secondary"
              class="shadow-md btn-icon"
              size="sm"
              @click="openTranscript(data.item.transcription)"
            >
              <div class="flex flex-row items-center justify-center">
                <div class="flex flex-row text-xs">View</div>
              </div>
            </b-button>
            <div v-else>No transcript</div>
          </template>
          <template #cell(recording)="data">
            <vue-sound
              v-if="data.item.recording"
              style="max-width: 350px"
              class="player-background rounded-lg"
              :file="data.item.recording"
              :show-skip="false"
            />
            <div v-else>No call recorded</div>
          </template>
        </b-table>
      </div>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BListGroup,
  BListGroupItem,
  BTable,
  BCard,
  BOverlay,
  BButton,
  BModal,
  BCol,
  BRow,
} from "bootstrap-vue";
import TotalCaseView from "../total-case-view/TotalCaseView.vue";
import VueSound from "vue-sound/src/components/VueSound";

export default {
  components: {
    BListGroup,
    BListGroupItem,
    BTable,
    BCard,
    BOverlay,
    VueSound,
    BButton,
    BModal,
    TotalCaseView,
    BCol,
    BRow,
  },
  props: {
    queryTranscription:{
      type: String,
      default: null
    },
    contactData: {
      type: Array,
      required: true,
    },
    isLoaded: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      isBusy2: false,
      fieldBy: "case_status",
      filterBy: "none",
      searchFilter: "",
      notificationsDateTo: "",
      notificationsDateFrom: "",
      checkboxSelected: ["notes"],
      // SELECTED VARS
      allChecked: false,
      indeterminateChecked: false,
      checkedViaRow: false,
      selectedRows: [],
      // LOCAL VARS FOR TABLE SORTING & PAGINATION
      pageOptions: [5, 7, 10, 15, { value: 100, text: "Show a lot" }],
      perPage: 10,
      isBusy: false,
      currentPage: 1,
      sortDesc: false,
      sortBy: "viewed",
      totalRows: 5,
      // LOCAL VARIABLES
      caseData: [],
      showModal: false,
      showTranscript: false,
      transcript: "",
      tableData: "",

      fields: [
        {
          key: "created_date",
          label: "Call Time",
        },
        {
          key: "caller",
          label: "Caller",
        },
        {
          key: "export_user",
          label: "Called",
        },
        {
          key: "case_number",
          label: "Case",
        },

        {
          key: "phone_number",
          label: "Phone Number",
        },
        {
          key: "transcription",
          label: "Call Transcript",
        },
        {
          key: "recording",
          label: "Play Call Recording",
        },
      ],
    };
  },
  computed: {
    checkSelect() {
      return this.selectedRows.length > 0 ? true : false;
    },
  },
  watch: {
    queryTranscription(newValue){
      console.log(newValue)
      if (newValue){
        this.openTranscript(newValue)
      }
    },
    isLoaded(newValue) {
      if (newValue >= 2) {
        this.isBusy = false;
      }
    },
    // eslint-disable-next-line no-unused-vars
    allChecked(newValue, oldValue) {
      if (newValue) {
        this.$refs.table.selectAllRows();

        return;
      }
      if (!newValue && !this.indeterminateChecked) {
        this.$refs.table.clearSelected();

        return;
      }
    },
  },

  methods: {
    openTranscript(transcript) {
      this.transcript = transcript;
      this.showTranscript = true;
    },
    getCaseData(case_number) {
      this.$AuthAxios.instance
        .get("/api-access/search/case-search/", {
          params: {
            single: true,
            caseNumber: case_number,
          },
        })
        .then((res) => {
          this.caseData = res.data.results[0];
          this.showModal = true;
        });
    },
    fPhone(item) {
      // FORMAT PHONE NUMBER
      var x = item.phone_number
        .replace(/\D/g, "")
        .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      return !x[2] ? x[1] : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
    },
    viewNotification(id) {
      if (this.$route.name !== "notifications") {
        this.$router.push({
          name: "notifications",
        });
      }
      this.$store.dispatch("notifications/updateSelectedNotification", id);
    },

    rowClass(item, type) {
      if (!item || type !== "row") return "relative";
      if (item._rowVariant === "success") return "relative table-success";
    },
  },
};
</script>


<style lang="css">
.player-background {
  background: #55dd92;
}
</style>